<template>
 <div>
  <div
   style="display: inline-flex; width: 100%; justify-content: space-between"
  >
   <div class="d-flex d-fit" style="width: 100%">
    <div>
     <a-button type="danger" @click="onReject()">반려처리 하기</a-button>
    </div>
    <div>
     <a-button @click="onVerify()">인증처리 하기</a-button>
    </div>
   </div>

   <div
    style="display: inline-flex; width: auto; justify-content: space-between"
   >
    <!-- <div style="margin-right: 10px">
     <a-button @click="onShowHistory()">선택 사용자 이력조회</a-button>
    </div>
    <div style="padding: 10px">총 선택 인원 {{ selectedRowKeys.length }}</div> -->
   </div>
  </div>
  <div style="margin-top: 10px">
   <a-table
    :row-selection="{
     selectedRowKeys: selectedRowKeys,
     onChange: onSelectChange,
    }"
    :columns="ucolumns"
    :data-source="udata"
    :pagination="false"
    bordered
    :rowKey="(record) => record.idx"
    size="middle"
    :scroll="{ x: '1600px', y: getHeight }"
   >
    <div slot="action" slot-scope="text, record">
     <a-tag v-if="record.companyPaperURL != ''"
      ><a :href="record.companyPaperURL" target="_blank">사업자등록증</a></a-tag
     >
    </div>
   </a-table>
  </div>
  <a-modal v-model="visible" title="반려 사유">
   <template slot="footer">
    <a-button key="back" @click="visible = false"> 취소 </a-button>
    <a-button key="submit" type="primary" @click="onSaveMessage()">
     저장
    </a-button>
   </template>
   <div>
    <a-textarea
     placeholder="사유 입력 후 저장을 해주세요"
     v-model="message"
     :rows="4"
    />
   </div>
  </a-modal>
 </div>
</template>

<script>
import { getAuth } from "firebase/auth";
import {
 getFirestore,
 addDoc,
 doc,
 collection,
 runTransaction,
 setDoc,
 orderBy,
 updateDoc,
 getDocs,
 query,
 where,
} from "firebase/firestore";
import {
 getDatabase,
 ref as dref,
 query as dquery,
 onValue,
 get,
 limitToLast,
} from "firebase/database";

import {
 getCurrentTime2,
 OPTION_TYPE,
 getCurrentDate3From,
 getCurrentDate3To,
 replaceAll,
 showSuccess,
 showError,
} from "../components/fnc";

import locale from "ant-design-vue/es/date-picker/locale/ko_KR";
import moment from "moment";
import "moment/locale/ko";
const auth = getAuth();
const db = getFirestore();
const db2 = getDatabase();

async function asyncForEach(array, callback) {
 for (let index = 0; index < array.length; index++) {
  await callback(array[index], index, array);
 }
}
Date.prototype.addDays = function (days) {
 const date = new Date(this.valueOf());
 date.setDate(date.getDate() + days);
 return date;
};

export default {
 data() {
  return {
   date: [],
   loading: null,
   searchWord: "",
   locale,
   data: [],
   visible: false,
   message: "",
   udata: [],
   cdata: [],
   odata: [],
   tempData: [],
   selectedRowKeys: [],
   expandedRowKeys: [],
   ucolumns: [
    {
     title: "순서",
     dataIndex: "idx",
     key: "idx",
     width: 50,
     align: "center",
     fixed: "left",
    },
    {
     title: "요청일",
     dataIndex: "eventdttm",
     key: "eventdttm",
     sorter: (a, b) => a.createdAt - b.createdAt,
     width: 170,
    },
    {
     title: "상호명",
     dataIndex: "title",
     key: "title",
     width: 100,
    },
    {
     title: "타입",
     dataIndex: "type",
     key: "type",
     width: 70,
    },
    {
     title: "작가/브랜드명",
     dataIndex: "partnerId",
     key: "partnerId",
     width: 110,
    },
    {
     title: "대표명",
     dataIndex: "ceoname",
     key: "ceoname",
     width: 80,
    },
    {
     title: "대표이메일",
     dataIndex: "companyEmail",
     key: "companyEmail",
     width: 180,
    },
    {
     title: "대표전화번호",
     dataIndex: "companyPhone",
     key: "companyPhone",
     width: 140,
    },
    {
     title: "담당자명",
     dataIndex: "managerName",
     key: "managerName",
     width: 80,
    },
    //  {
    //   title: "담당자이메일",
    //   dataIndex: "managerEmail",
    //   key: "managerEmail",
    //   width: 180,
    //  },
    {
     title: "담당자전화번호",
     dataIndex: "managerPhone",
     key: "managerPhone",
     width: 140,
    },
    {
     title: "사업자번호",
     dataIndex: "businessNum",
     key: "businessNum",
     width: 140,
    },
     {
      title: "공급가율",
      dataIndex: "ratio",
      key: "ratio",
      width: 70,
     },
    {
     title: "사업자등록증",
     key: "bussinessURL",
     width: 110,
     scopedSlots: { customRender: "action" },
    },
    {
     title: "인증상태",
     dataIndex: "verficated",
     key: "verficated",
    },
   ],
  };
 },
 methods: {
  async onSaveMessage() {
   const self = this;
   if (this.message) {
    this.loading = this.$vs.loading();
    await asyncForEach(
     this.expandedRowKeys,
     async function (item, index, array) {
      const updateRef = doc(db, "request_partner", item.id);

      await updateDoc(updateRef, {
       verficated: null,
       rejectMsg: self.message,
      });
     }
    );
    this.loading.close();
    this.visible = false;
    showSuccess({
     notification: this.$vs.notification,
     msg: "성공적으로 저장 하였습니다.",
    });
    this.onLoadUser();
    this.selectedRowKeys = [];
   } else {
    showError({
     notification: this.$vs.notification,
     msg: "사유를 입력해주세요.",
    });
   }
  },
  onSelectChange(selectedRowKeys) {
   const self = this;
   this.expandedRowKeys = [];

   this.selectedRowKeys = selectedRowKeys;

   selectedRowKeys.forEach((ii) => {
    self.odata.forEach((item) => {
     if (parseInt(item.idx) === parseInt(ii)) {
      self.expandedRowKeys.push(item);
     }
    });
   });
  },
  async onVerify() {
   const self = this;
   if (this.selectedRowKeys.length === 0) {
    showError({
     notification: this.$vs.notification,
     msg: "사용자를 선택해주세요",
    });
   } else {
    await asyncForEach(
     this.expandedRowKeys,
     async function (item, index, array) {
      const updateRef = doc(db, "request_partner", item.id);

      await updateDoc(updateRef, {
       verficated: true,
      });
     }
    );
    showSuccess({
     notification: this.$vs.notification,
     msg: "성공적으로 업데이트 하였습니다.",
    });
    this.onLoadUser();
    this.selectedRowKeys = [];
   }
  },
  async onReject() {
   const self = this;
   if (this.selectedRowKeys.length === 0) {
    showError({
     notification: this.$vs.notification,
     msg: "사용자를 선택해주세요",
    });
   } else {
    this.visible = true;
   }
  },
  async onLoadUser() {
   const self = this;

   this.odata = [];
   this.udata = [];

   this.loading = this.$vs.loading();

   const q = query(
    collection(db, "request_partner"),
    orderBy("createdAt", "desc")
   );

   const _data = [];
   let _idx = 1;
   const querySnapshot = await getDocs(q);
   let _current = [];
   querySnapshot.forEach((doc) => {
    const _t = doc.data();
    _t["idx"] = _idx;
    const _a = _t.type ? (_t.type === "A" ? "작가" : "출판사") : "출판사";

    _t["type"] = _a;
    _t["id"] = doc.id;

    _t["verficated"] = doc.data().verficated
     ? "인증완료"
     : doc.data().verficated === null
     ? "반려"
     : "대기";
    _t["eventdttm"] = getCurrentTime2(_t.createdAt);

    _idx++;

    _data.push(_t);
   });
   this.odata = _data;
   this.udata = _data;
   this.loading.close();
  },
  onSearch() {
   const self = this;
   this.udata = [];
   this.udata = this.odata.filter((item) => {
    if (
     item.companyEmail.toLowerCase().indexOf(self.searchWord.toLowerCase()) >= 0
    ) {
     return item;
    }
   });
   this.selectedRowKeys = [];
  },
 },
 computed: {
  getHeight() {
   return "calc(100vh - 210px)";
  },
  getHeight2() {
   return "calc(100vh - 300px)";
  },
 },
 mounted() {
  this.onLoadUser();
 },
};
</script>

<style>
</style>