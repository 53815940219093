<template>
 <div>
  <div
   style="display: inline-flex; width: 100%; justify-content: space-between"
  >
   <div class="d-flex d-end" style="width: 100%">
    <div>
     <a-button
      @click="fncDeleteCoupon()"
      type="danger"
      style="width: 80px; margin-right: 5px"
      >삭제</a-button
     >
     <a-button
      type="primary"
      @click="onCreateCoupon()"
      style="width: 80px; margin-right: 5px"
      >생성</a-button
     >
     <a-button
      type="dashed"
      style="width: 80px; margin-right: 5px"
      @click="fncModifyCoupon()"
      >수정</a-button
     >
     <a-button type="default" style="width: 100px" @click="fncAssignCoupon()"
      >사용자 지정</a-button
     >
    </div>
   </div>

   <div
    style="display: inline-flex; width: auto; justify-content: space-between"
   ></div>
  </div>
  <div style="margin-top: 10px">
   <a-table
    :row-selection="{
     selectedRowKeys: selectedRowKeys,
     onChange: onSelectChange,
    }"
    :columns="ucolumns"
    :data-source="udata"
    :pagination="false"
    bordered
    :rowKey="(record) => record.idx"
    size="middle"
    :scroll="{ y: getHeight }"
   >
   </a-table>
  </div>

  <a-modal :closable="false" v-model="dialogCoupon">
   <template slot="footer">
    <a-button key="back" @click="dialogCoupon = false"> 닫기 </a-button>
    <a-button
     key="submit"
     type="primary"
     @click="fncCreateCoupon()"
     v-if="coupon['id'] === undefined"
    >
     생성
    </a-button>
    <a-button
     key="submit"
     type="primary"
     @click="fncCreateCoupon()"
     v-if="coupon['id']"
    >
     수정
    </a-button>
   </template>

   <a-row
    class="card-header-padding card-border-bottom"
    style="margin-bottom: 10px; padding-bottom: 0px"
   >
    <h5>쿠폰 생성</h5>
   </a-row>

   <div style="height: 100%; padding: 0px; margin: 0px">
    <div style="margin-top: 10px">
     <h6>쿠폰번호 : {{ coupon.number }}</h6>
    </div>
    <div style="margin-top: 10px"><h6>이름</h6></div>
    <a-input
     v-model="coupon.name"
     placeholder="쿠폰명 을 입력해주세요"
    ></a-input>
    <div class="d-flex d-start">
     <div>
      <div style="margin-top: 10px"><h6>할인율</h6></div>
      <a-input-number
       :default-value="10"
       :min="1"
       :max="100"
       v-model="coupon.val"
       :formatter="(value) => `${value}%`"
       :parser="(value) => value.replace('%', '')"
      />
     </div>
     <div style="margin-left: 10px">
      <div style="margin-top: 10px"><h6>제약</h6></div>
      <a-input-number
       style="width: 200px"
       :default-value="1"
       :min="1"
       :max="100000"
       v-model="coupon.val2"
       :formatter="(value) => `${value}원이상`"
       :parser="(value) => value.replace('원이상', '')"
      />
     </div>
    </div>
    <div style="margin-top: 10px"><h6>타입</h6></div>
    <div>
     <a-radio-group v-model="coupon.type" name="radioGroup" default-value="C">
      <a-radio value="S"> 시스템용 </a-radio>
      <a-radio value="C"> 사용자용 </a-radio>
     </a-radio-group>
    </div>
    <div style="margin-top: 10px"><h6>기간</h6></div>
    <div>
     <a-radio-group name="radioGroup" v-model="coupon.mode" default-value="P">
      <a-radio value="D"> 만료일 지정 </a-radio>
      <a-radio value="P"> 사용기간 지정 </a-radio>
     </a-radio-group>
     <a-input
      v-mask="'####/##/##'"
      placeholder=""
      style="width: 120px"
      autocomplete="off"
      pattern="[0-9]*"
      inputmode="numeric"
      v-if="coupon.mode === 'D'"
      v-model="coupon.duedate"
     ></a-input>

     <a-input-number
      v-if="coupon.mode === 'P'"
      :default-value="90"
      :min="1"
      :max="365"
      v-model="coupon.period"
      :formatter="(value) => `${value}일`"
      :parser="(value) => value.replace('일', '')"
     />
    </div>
   </div>
  </a-modal>

  <a-modal :closable="false" v-model="dialogAssign" width="620px">
   <template slot="footer">
    <a-button key="back" @click="dialogAssign = false"> 닫기 </a-button>
    <a-button key="submit" type="primary" @click="fncAssignUser()">
     추가
    </a-button>
   </template>

   <a-row
    class="card-header-padding card-border-bottom"
    style="margin-bottom: 10px; padding-bottom: 0px"
   >
    <h5>쿠폰 할당</h5>
   </a-row>

   <div style="height: 100%; padding: 0px; margin: 0px">
    <div style="margin-top: 10px"><h6>이메일</h6></div>
    <a-input-search
     placeholder="지정할 이메일을 검색해주세요"
     enter-button="검색"
     v-model="user.email"
     @search="onSearchUser"
    />
    <table style="margin-top: 10px">
     <thead>
      <tr>
       <th>항목</th>
       <th>내역</th>
      </tr>
     </thead>
     <tbody>
      <tr>
       <td>이메일</td>
       <td>[{{ user.email }}]</td>
      </tr>

      <tr>
       <td>이름</td>
       <td>[{{ user.name }}]</td>
      </tr>

      <tr>
       <td>전화번호</td>
       <td>[{{ user.phone }}]</td>
      </tr>
     </tbody>
    </table>
    <a-table
     style="margin-top: 10px"
     :columns="ucolumns2"
     :data-source="datausers"
     :scroll="{ y: getHeight2 }"
    >
    </a-table>
   </div>
  </a-modal>
 </div>
</template>

<script>
import {
 getFirestore,
 addDoc,
 deleteDoc,
 doc,
 collection,
 runTransaction,
 setDoc,
 orderBy,
 updateDoc,
 getDoc,
 getDocs,
 query,
 where,
} from "firebase/firestore";

import {
 getCurrentTime2,
 OPTION_TYPE,
 getCurrentDate3From,
 getCurrentDate3To,
 replaceAll,
 getHex,
 showSuccess,
 showError,
} from "../components/fnc";
import { getAuth } from "firebase/auth";
import locale from "ant-design-vue/es/date-picker/locale/ko_KR";
import moment from "moment";
import "moment/locale/ko";

const db = getFirestore();
const auth = getAuth();
async function asyncForEach(array, callback) {
 for (let index = 0; index < array.length; index++) {
  await callback(array[index], index, array);
 }
}
Date.prototype.addDays = function (days) {
 const date = new Date(this.valueOf());
 date.setDate(date.getDate() + days);
 return date;
};

export default {
 data() {
  return {
   date: [],
   user: {
    cid: "",
    cnumber: "",
    uid: "",
    email: "",
    phone: "",
    name: "",
    cname: "",
    cvalue: 0,
    cvalue2: 0,
    cmode: "",
    cduedate: "",
    cperiod: "",
    createdAt: "",
   },
   coupon: {
    name: "",
    val: 1,
    val2: 1,
    type: "C",
    duedate: "20231224",
    period: 90,
    mode: "P",
    number: "",
   },
   loading: null,
   searchWord: "",
   locale,
   data: [],
   dialogCoupon: false,
   dialogAssign: false,
   visible: false,
   udata: [],
   cdata: [],
   odata: [],
   datausers: [],
   tempData: [],
   selectedRowKeys: [],
   expandedRowKeys: [],
   ucolumns: [
    {
     title: "순서",
     dataIndex: "idx",
     key: "idx",
     width: 50,
     align: "center",
     fixed: "left",
    },
    {
     title: "생성일",
     dataIndex: "eventdttm",
     key: "eventdttm",
     sorter: (a, b) => a.createdAt - b.createdAt,
     width: 170,
    },
    {
     title: "쿠폰명",
     dataIndex: "name",
     key: "name",
    },
    {
     title: "쿠폰번호",
     dataIndex: "number",
     key: "number",
     width: 150,
    },
    {
     title: "할인율",
     dataIndex: "value",
     key: "value",
     width: 70,
    },
    {
     title: "타입",
     dataIndex: "type",
     key: "type",
     width: 100,
    },
    // {
    //  title: "상태",
    //  dataIndex: "status",
    //  key: "status",
    //  width: 100,
    // },
    {
     title: "만기일",
     dataIndex: "duedate",
     key: "duedate",
     width: 150,
    },
    {
     title: "사용기간",
     dataIndex: "period",
     key: "period",
     width: 150,
    },
   ],
   ucolumns2: [
    {
     title: "추가일",
     dataIndex: "eventdttm",
     key: "eventdttm",
     sorter: (a, b) => a.createdAt - b.createdAt,
     width: 200,
    },
    {
     title: "이름",
     dataIndex: "name",
     key: "name",
    },
    {
     title: "이메일",
     dataIndex: "email",
     key: "email",
     width: 200,
    },
    // {
    //  title: "전화번호",
    //  dataIndex: "phone",
    //  key: "phone",
    //  width: 100,
    // },
   ],
  };
 },
 methods: {
  async fncAssignUser() {
   const _loading = this.$vs.loading();
   await addDoc(collection(db, "coupon_user"), this.user);

   showSuccess({
    notification: this.$vs.notification,
    msg: "성공적으로 추가되었습니다.",
   });
   _loading.close();
   this.dialogAssign = false;
   this.user = {
    cid: "",
    cnumber: "",
    uid: "",
    email: "",
    phone: "",
    name: "",
    cname: "",
    cvalue: 0,
    cvalue2: 0,
    cmode: "",
    cduedate: "",
    cperiod: "",
    createdAt: "",
    usetime: "",
    status: "",
   };
   this.selectedRowKeys = [];
  },
  async onSearchUser() {
   const self = this;

   this.user.phone = "";
   this.user.name = "";
   const q = query(
    collection(db, "User"),
    where("email", "==", this.user.email.trim().toLowerCase())
   );

   const querySnapshot = await getDocs(q);
   querySnapshot.forEach((doc) => {
    self.user.phone = doc.data().phone;
    self.user.name = doc.data().username;
    self.user.uid = doc.data().uid;
   });
  },
  async fncCreateCoupon() {
   const _loading = this.$vs.loading();

   this.coupon["duedate"] = replaceAll(this.coupon["duedate"],'/','')
   if (this.coupon["id"]) {
    await setDoc(doc(db, "coupon_master", this.coupon["id"]), this.coupon, {
     merge: true,
    });
   } else {
    this.coupon["status"] = "사용중";
    this.coupon["uid"] = auth.currentUser.uid;
    this.coupon["createdAt"] = new Date().getTime();
    await addDoc(collection(db, "coupon_master"), this.coupon);
   }
   _loading.close();
   this.dialogCoupon = false;
   this.onLoadCoupon();
   this.selectedRowKeys = [];
  },
  onCreateCoupon() {
   this.dialogCoupon = true;
   this.coupon = {
    name: "",
    val: 1,
    val2: 1,
    type: "C",
    duedate: "20231224",
    number: getHex("P") + new Date().getTime(),
    period: 90,
    mode: "P",
   };
  },
  onSelectChange(selectedRowKeys) {
   const self = this;
   this.expandedRowKeys = [];

   this.selectedRowKeys = selectedRowKeys;

   selectedRowKeys.forEach((ii) => {
    self.odata.forEach((item) => {
     if (parseInt(item.idx) === parseInt(ii)) {
      self.expandedRowKeys.push(item);
     }
    });
   });
  },
  async fncDeleteCoupon() {
   const self = this;
   if (this.selectedRowKeys.length === 0) {
    showError({
     notification: this.$vs.notification,
     msg: "쿠폰을 선택해주세요",
    });
   } else {
    await asyncForEach(
     this.expandedRowKeys,
     async function (item, index, array) {
      await deleteDoc(doc(db, "coupon_master", item.id));
     }
    );
    showSuccess({
     notification: this.$vs.notification,
     msg: "성공적으로 삭제되었습니다.",
    });
    this.onLoadCoupon();
    this.selectedRowKeys = [];
   }
  },
  async fncAssignCoupon() {
   const self = this;
   if (this.selectedRowKeys.length === 0) {
    showError({
     notification: this.$vs.notification,
     msg: "쿠폰을 선택해주세요",
    });
   } else if (this.selectedRowKeys.length > 1) {
    showError({
     notification: this.$vs.notification,
     msg: "지정 쿠폰은 1개만 가능합니다.",
    });
   } else {
    const docRef = doc(db, "coupon_master", this.expandedRowKeys[0].id);
    const docSnap = await getDoc(docRef);

    this.user.cid = this.expandedRowKeys[0].id;
    this.user.cname = docSnap.data().name;
    this.user.cmode = docSnap.data().mode;
    this.user.cvalue = docSnap.data().val;
    this.user.cvalue2 = docSnap.data().val2;
    this.user.cnumber = docSnap.data().number;
    this.user.cduedate = docSnap.data().duedate;
    this.user.cperiod = docSnap.data().period;
    this.user.createdAt = new Date().getTime();
    this.user.status = "N";
    this.user.usetime = "";

    const q = query(
     collection(db, "coupon_user"),
     where("cid", "==", this.user.cid)
    );
    this.datausers = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
     const _t = doc.data();
     _t["eventdttm"] = getCurrentTime2(_t.createdAt);
     self.datausers.push(_t);
    });

    this.dialogAssign = true;
   }
  },
  async fncModifyCoupon() {
   const self = this;
   if (this.selectedRowKeys.length === 0) {
    showError({
     notification: this.$vs.notification,
     msg: "쿠폰을 선택해주세요",
    });
   } else if (this.selectedRowKeys.length > 1) {
    showError({
     notification: this.$vs.notification,
     msg: "수정은 1개만 가능합니다.",
    });
   } else {
    const docRef = doc(db, "coupon_master", this.expandedRowKeys[0].id);
    const docSnap = await getDoc(docRef);

    this.coupon = docSnap.data();
    this.coupon["id"] = this.expandedRowKeys[0].id;
    this.dialogCoupon = true;
   }
  },
  async onLoadCoupon() {
   const self = this;

   this.odata = [];
   this.udata = [];

   this.loading = this.$vs.loading();

   const q = query(collection(db, "coupon_master"));

   const _data = [];
   let _idx = 1;
   const querySnapshot = await getDocs(q);
   let _current = [];
   querySnapshot.forEach((doc) => {
    const _t = doc.data();
    _t["idx"] = _idx;
    const _a = _t.type === "S" ? "시스템용" : "사용자용";

    _t["type"] = _a;

    _t["value"] = _t.val.toString() + "%";

    _t["duedate"] = _t.mode === "D" ? _t.duedate : "-";
    _t["period"] = _t.mode === "P" ? _t.period + "일" : "-";

    _t["id"] = doc.id;

    _t["eventdttm"] = getCurrentTime2(_t.createdAt);

    _idx++;

    _data.push(_t);
   });
   this.odata = _data;
   this.udata = _data;
   this.loading.close();
  },
  onSearch() {
   const self = this;
   this.udata = [];
   this.udata = this.odata.filter((item) => {
    if (
     item.companyEmail.toLowerCase().indexOf(self.searchWord.toLowerCase()) >= 0
    ) {
     return item;
    }
   });
   this.selectedRowKeys = [];
  },
 },
 computed: {
  getHeight() {
   return "calc(100vh - 210px)";
  },
  getHeight2() {
   return "calc(100vh - 300px)";
  },
 },
 mounted() {
  this.onLoadCoupon();
 },
};
</script>

<style>
</style>