<template>
 <!-- Information Card 2 -->
 <a-card :bordered="false" class="card-info-2 h-full" >
  <div
   class="col-content h-full"
   style="background-image: url('images/info-card-2.jpg');"
  >
   <div class="card-content">
    <h5>{{title}}</h5>
    <h1 style="color: white" >{{count.toLocaleString()}}</h1>
    <!-- <h6>* 내부 계정 제외수</h6> -->
   </div>
  </div>
 </a-card>
 <!-- / Information Card 2 -->
</template>

<script>
export default {
 props: {
  count: {
   type: Number,
   default: 0,
  },
  title: {
   type: String,
   default: "",
  },
 },
 data() {
  return {};
 },
};
</script>