<template>
 <div style="overflow-y: hidden; padding: 10px">
  <a-row :gutter="24" type="flex" align="stretch">
   <a-col :span="12" :xl="9" class="mb-24">
    <CardInfo></CardInfo>
   </a-col>
  </a-row>
  <a-row
   :gutter="24"
   type="flex"
   align="stretch"
   style="height: calc(100vh - 350px); overflow-y: scroll"
  >
  
  </a-row>
 </div>
</template>

<script>
import WidgetCounter from "../components/Widgets/WidgetCounter";
import CardOrderHistory from "../components/Cards/CardOrderHistory";
import CardInfo from "../components/Cards/CardInfo";
import CardInfo2 from "../components/Cards/CardInfo2";
import CardInfo3 from "../components/Cards/CardInfo3";

import {
 getFirestore,
 addDoc,
 doc,
 collection,
 runTransaction,
 setDoc,
 orderBy,
 updateDoc,
 getDocs,
 query,
 where,
} from "firebase/firestore";
const db = getFirestore();

export default {
 components: {
  WidgetCounter,
  CardInfo,
  CardInfo2,
  CardInfo3,
 },
 data() {
  return {
   total: 0,
  };
 },
 methods: {

 },
 async mounted() {
  //
 },
};
</script>

<style>
</style>