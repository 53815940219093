<template>
 <div>
  <div
   style="display: inline-flex; width: 100%; justify-content: space-between"
  >
   <div class="d-flex d-end" style="width: 100%">
    <div>
     <a-button
      @click="fncDeleteEvents()"
      type="danger"
      style="width: 80px; margin-right: 5px"
      >삭제</a-button
     >
     <a-button
      type="primary"
      @click="onCreateEvents()"
      style="width: 80px; margin-right: 5px"
      >생성</a-button
     >
     <a-button
      type="dashed"
      style="width: 80px; margin-right: 5px"
      @click="fncModifyEvents()"
      >수정</a-button
     >
    </div>
   </div>

   <div
    style="display: inline-flex; width: auto; justify-content: space-between"
   ></div>
  </div>
  <div class="mt-10">
   <a-table
    :row-selection="{
     selectedRowKeys: selectedRowKeys,
     onChange: onSelectChange,
    }"
    :columns="ucolumns"
    :data-source="udata"
    :pagination="false"
    bordered
    :rowKey="(record) => record.idx"
    size="middle"
    :scroll="{ y: getHeight }"
   >
   </a-table>
  </div>

  <a-modal :closable="false" v-model="dialogEvents" width="870px">
   <template slot="footer">
    <a-button key="back" @click="dialogEvents = false"> 닫기 </a-button>
    <a-button
     key="submit"
     type="primary"
     @click="fncCreateEvents()"
     v-if="events['id'] === undefined"
    >
     생성
    </a-button>
    <a-button
     key="submit"
     type="primary"
     @click="fncCreateEvents()"
     v-if="events['id']"
    >
     수정
    </a-button>
   </template>

   <a-row
    class="card-header-padding card-border-bottom"
    style="margin-bottom: 10px; padding-bottom: 0px"
   >
    <h5>이벤트 생성</h5>
   </a-row>

   <div style="height: 100%; padding: 0px; margin: 0px">
    <div class="d-flex d-fit mt-10">
     <div style="width: 100%">
      <div><h6>배너명</h6></div>
      <a-input
       v-model="events.eventName"
       placeholder="배너명 을 입력해주세요"
      ></a-input>
     </div>
     <div style="margin-left: 10px; width: 400px">
      <div><h6>이미지 CSS</h6></div>
      <a-input
       v-model="events.eventImageCSS"
       placeholder="이미지 CSS를 입력해주세요"
      ></a-input>
     </div>
    </div>

    <div class="mt-10 d-flex d-fit">
     <div style="width: 100%">
      <div><h6>타이틀</h6></div>
      <a-input
       v-model="events.eventTitle1"
       placeholder="상단 타이틀을 입력해주세요"
      ></a-input>
      <a-input
       v-model="events.eventTitle2"
       placeholder="중간 타이틀을 입력해주세요"
      ></a-input>
      <a-input
       v-model="events.eventTitle3"
       placeholder="하단 타이틀을 입력해주세요"
      ></a-input>
     </div>
     <div style="margin-left: 10px; width: 400px">
      <div><h6>CSS</h6></div>
      <a-input
       v-model="events.eventTitle1CSS"
       placeholder="상단 CSS 입력해주세요"
      ></a-input>
      <a-input
       v-model="events.eventTitle2CSS"
       placeholder="중간 CSS 입력해주세요"
      ></a-input>
      <a-input
       v-model="events.eventTitle3CSS"
       placeholder="하단 CSS 입력해주세요"
      ></a-input>
     </div>
    </div>

    <div class="d-flex d-start">
     <div>
      <div class="mt-10"><h6>배포 위치</h6></div>
      <div>
       <a-radio-group
        v-model="events.type"
        name="radioGroup"
        style="margin-top: 8px"
        default-value="main"
       >
        <a-radio value="main"> 홈상단 </a-radio>
        <a-radio value="submain"> 홈중간 </a-radio>
        <a-radio value="piece"> 책조각상세 </a-radio>
        <a-radio value="collect"> 컬랙션상세 </a-radio>
        <a-radio value="book"> 책상세 </a-radio>
       </a-radio-group>
      </div>
     </div>
     <div>
      <div class="mt-10"><h6>시작일</h6></div>
      <div>
       <a-input
        v-mask="'####/##/##'"
        placeholder=""
        style="width: 120px"
        autocomplete="off"
        pattern="[0-9]*"
        inputmode="numeric"
        v-model="events.startdateFormat"
       ></a-input>
      </div>
     </div>
     <div style="margin-left: 10px">
      <div class="mt-10"><h6>만료일</h6></div>
      <div>
       <a-input
        v-mask="'####/##/##'"
        placeholder=""
        style="width: 120px"
        autocomplete="off"
        pattern="[0-9]*"
        inputmode="numeric"
        v-model="events.duedateFormat"
       ></a-input>
      </div>
     </div>
     <div style="margin-left: 10px">
      <div class="mt-10"><h6>가중치</h6></div>
      <div>
       <a-input
        v-mask="'####/##/##'"
        placeholder=""
        style="width: 50px"
        autocomplete="off"
        pattern="[0-9]*"
        inputmode="numeric"
        v-model="events.weight"
       ></a-input>
      </div>
     </div>

     <div style="margin-left: 10px">
      <div class="mt-10"><h6>배포상태</h6></div>
      <div class="mt-10">
       <a-switch v-model="events.status" style="margin-top: 5px">
        <a-icon slot="checkedChildren" type="check" />
        <a-icon slot="unCheckedChildren" type="close" />
       </a-switch>
      </div>
     </div>
    </div>

    <div
     class="clickable mt-10"
     style="width: 100%; height: 100px; border: 1px dashed gray"
     @click="onLoadImage()"
    >
     <input
      type="file"
      ref="file"
      v-show="false"
      id="avatar"
      name="avatar"
      accept="image/*"
      @change="onHandleChange"
     />
     <img
      v-if="events.eventImageURL"
      :src="events.eventImageURL"
      alt="avatar"
      style="
       object-fit: cover;
       width: 100%;
       height: 100%;
       border: 1px dashed #e2e6e9;
       border-radius: 5px;
      "
     />
     <div
      v-else
      class="clickable"
      style="text-align: center; padding-top: 40px"
     >
      <i class="fa-regular fa-image"></i>&nbsp;이미지 불러오기
     </div>
    </div>

    <div class="mt-10">
     <div><h6>URL</h6></div>
     <a-input
      v-model="events.href"
      placeholder="링크 URL를 입력해주세요"
     ></a-input>
    </div>
   </div>
  </a-modal>
 </div>
</template>

<script>
import {
 getFirestore,
 addDoc,
 deleteDoc,
 doc,
 collection,
 runTransaction,
 setDoc,
 orderBy,
 updateDoc,
 getDoc,
 getDocs,
 query,
 where,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
const storage = getStorage();
import {
 getCurrentTime2,
 OPTION_TYPE,
 getCurrentDate3From,
 getCurrentDate3To,
 getCurrentDate,
 replaceAll,
 getHex,
 showSuccess,
 showError,
} from "../components/fnc";
import { getAuth } from "firebase/auth";
import locale from "ant-design-vue/es/date-picker/locale/ko_KR";
import moment from "moment";
import "moment/locale/ko";

const db = getFirestore();
const auth = getAuth();
async function asyncForEach(array, callback) {
 for (let index = 0; index < array.length; index++) {
  await callback(array[index], index, array);
 }
}
Date.prototype.addDays = function (days) {
 const date = new Date(this.valueOf());
 date.setDate(date.getDate() + days);
 return date;
};

export default {
 data() {
  return {
   date: [],
   events: {
    eventName: "",
    eventTitle1: "",
    eventTitle1CSS: "text-align:center;color:#ffffff;font-size:12px",
    eventTitle2: "",
    eventTitle2CSS: "text-align:center;color:#ffffff;font-size:12px",
    eventTitle3: "",
    eventTitle3CSS: "text-align:center;color:#ffffff;font-size:12px",
    eventImageURL: "",
    eventImageCSS: "object-position: center center;object-fit:cover",
    duedate: "",
    duedateFormat: "",
    startdate: "",
    startdateFormat: "",
    position: "",
    status: true,
    weight: 999,
    href: "",
    hreType: "",
    uid: "",
    createdAt: "",
   },
   loading: null,
   searchWord: "",
   locale,
   data: [],
   dialogEvents: false,
   dialogAssign: false,
   visible: false,
   udata: [],
   cdata: [],
   odata: [],
   datausers: [],
   tempData: [],
   selectedRowKeys: [],
   expandedRowKeys: [],
   ucolumns: [
    {
     title: "순서",
     dataIndex: "idx",
     key: "idx",
     width: 50,
     align: "center",
     fixed: "left",
    },
    {
     title: "생성일",
     dataIndex: "eventdttm",
     key: "eventdttm",
     sorter: (a, b) => a.createdAt - b.createdAt,
     width: 170,
    },
    {
     title: "이벤트명",
     dataIndex: "eventName",
     key: "eventName",
    },
    {
     title: "위치",
     dataIndex: "position",
     key: "position",
     width: 110,
    },
    {
     title: "가중치",
     dataIndex: "weight",
     key: "weight",
     width: 70,
    },
    {
     title: "시작일",
     dataIndex: "startdateFormat",
     key: "startdateFormat",
     width: 150,
    },
    {
     title: "만료일",
     dataIndex: "duedateFormat",
     key: "duedateFormat",
     width: 150,
    },
   ],
  };
 },
 methods: {
  async onChangeFile(_file) {
   const self = this;
   const _key = new Date().getTime();
   this.loading = true;
   this.uploaded = true;

   const _name = _file.name.replace(
    /[~`!#$%\^&*+=\-\[\]\\';,/{}()|\\":<>\?]/g,
    ""
   );
   const storageRef = ref(storage, `events/${_key}/${_name}`);

   const metadata = {
    contentType: "image/png",
   };

   const uploadTask = await uploadBytes(storageRef, _file, metadata);
   //    console.log("1", this.getUid);
   getDownloadURL(uploadTask.ref).then((downloadURL) => {
    self.events.eventImageURL = downloadURL;
   });
  },
  async createFile(file, idx) {
   await this.onChangeFile(file);
  },
  onHandleChange(e) {
   this.createFile(this.$refs.file.files[0], 0);
  },
  onLoadImage() {
   this.$refs.file.click();
  },
  async fncCreateEvents() {
   const _loading = this.$vs.loading();
   this.events["duedate"] = new Date(
    getCurrentDate3From(new Date(this.events.duedateFormat))
   ).getTime();
   this.events["startdate"] = new Date(
    getCurrentDate3To(new Date(this.events.startdateFormat))
   ).getTime();

   if (this.events["id"]) {
    await setDoc(doc(db, "events", this.events["id"]), this.events, {
     merge: true,
    });
   } else {
    this.events["uid"] = auth.currentUser.uid;
    this.events["createdAt"] = new Date().getTime();
    await addDoc(collection(db, "events"), this.events);
   }
   _loading.close();
   this.dialogEvents = false;
   this.onLoadEvents();
   this.selectedRowKeys = [];
  },
  onCreateEvents() {
   this.dialogEvents = true;
   this.events = {
    eventName: "",
    eventTitle1: "",
    eventTitle1CSS: "text-align:center;color:#ffffff;font-size:12px",
    eventTitle2: "",
    eventTitle2CSS: "text-align:center;color:#ffffff;font-size:12px",
    eventTitle3: "",
    eventTitle3CSS: "text-align:center;color:#ffffff;font-size:12px",
    eventImageURL: "",
    eventImageCSS: "object-position: center center;object-fit:cover",
    duedate: new Date().addDays(30).getTime(),
    duedateFormat: getCurrentDate(new Date().addDays(30)),
    startdate: new Date().addDays(1).getTime(),
    startdateFormat: getCurrentDate(new Date().addDays(1)),
    position: "main",
    status: true,
    weight: 999,
    href: "",
    uid: "",
    createdAt: "",
   };
  },
  onSelectChange(selectedRowKeys) {
   const self = this;
   this.expandedRowKeys = [];

   this.selectedRowKeys = selectedRowKeys;

   selectedRowKeys.forEach((ii) => {
    self.odata.forEach((item) => {
     if (parseInt(item.idx) === parseInt(ii)) {
      self.expandedRowKeys.push(item);
     }
    });
   });
  },
  async fncDeleteEvents() {
   const self = this;
   if (this.selectedRowKeys.length === 0) {
    showError({
     notification: this.$vs.notification,
     msg: "이벤트를 선택해주세요",
    });
   } else {
    await asyncForEach(
     this.expandedRowKeys,
     async function (item, index, array) {
      await deleteDoc(doc(db, "events", item.id));
     }
    );
    showSuccess({
     notification: this.$vs.notification,
     msg: "성공적으로 삭제되었습니다.",
    });
    this.onLoadEvents();
    this.selectedRowKeys = [];
   }
  },
  async fncModifyEvents() {
   const self = this;
   if (this.selectedRowKeys.length === 0) {
    showError({
     notification: this.$vs.notification,
     msg: "이벤트를 선택해주세요",
    });
   } else if (this.selectedRowKeys.length > 1) {
    showError({
     notification: this.$vs.notification,
     msg: "수정은 1개만 가능합니다.",
    });
   } else {
    const docRef = doc(db, "events", this.expandedRowKeys[0].id);
    const docSnap = await getDoc(docRef);

    this.events = docSnap.data();
    this.events["id"] = this.expandedRowKeys[0].id;
    this.dialogEvents = true;
   }
  },
  async onLoadEvents() {
   const self = this;

   this.odata = [];
   this.udata = [];

   this.loading = this.$vs.loading();

   const q = query(collection(db, "events"));

   const _data = [];
   let _idx = 1;
   const querySnapshot = await getDocs(q);
   let _current = [];
   querySnapshot.forEach((doc) => {
    const _t = doc.data();
    _t["idx"] = _idx;

    _t["id"] = doc.id;

    if (doc.data().type === "main") {
     _t["position"] = "홈상단";
    } else if (doc.data().type === "submain") {
     _t["position"] = "홈중간";
    } else if (doc.data().type === "piece") {
     _t["position"] = "책조각상세";
    } else if (doc.data().type === "collect") {
     _t["position"] = "컬랙션상세";
    } else if (doc.data().type === "book") {
     _t["position"] = "책상세";
    }

    _t["eventdttm"] = getCurrentTime2(_t.createdAt);

    _idx++;

    _data.push(_t);
   });
   this.odata = _data;
   this.udata = _data;
   this.loading.close();
  },
  onSearch() {
   const self = this;
   this.udata = [];
   this.udata = this.odata.filter((item) => {
    if (
     item.companyEmail.toLowerCase().indexOf(self.searchWord.toLowerCase()) >= 0
    ) {
     return item;
    }
   });
   this.selectedRowKeys = [];
  },
 },
 computed: {
  getHeight() {
   return "calc(100vh - 210px)";
  },
  getHeight2() {
   return "calc(100vh - 300px)";
  },
 },
 mounted() {
  this.onLoadEvents();
 },
};
</script>

<style>
</style>