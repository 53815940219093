<template>
 <div>
  <div
   style="display: inline-flex; width: 100%; justify-content: space-between"
  >
   <div style="width: 100%" class="d-flex d-fit">
    <div>
     <a-input-search
      placeholder="도서명 검색"
      style="width: 200px"
      v-model="searchWord"
      @search="onSearch"
     />
    </div>
    <div>
     <a-button @click="onVerify()">검수완료 처리하기</a-button
     ><a-button type="danger" style="margin-left: 10px" @click="onReject()"
      >반려 처리하기</a-button
     >
    </div>
   </div>

   <div
    style="display: inline-flex; width: auto; justify-content: space-between"
   >
    <!-- <div style="margin-right: 10px">
     <a-button @click="onShowHistory()">선택 사용자 이력조회</a-button>
    </div>
    <div style="padding: 10px">총 선택 인원 {{ selectedRowKeys.length }}</div> -->
   </div>
  </div>
  <div style="margin-top: 10px">
   <a-table
    :row-selection="{
     selectedRowKeys: selectedRowKeys,
     onChange: onSelectChange,
    }"
    :columns="ucolumns"
    :data-source="udata"
    :pagination="false"
    bordered
    :rowKey="(record) => record.no"
    size="middle"
    :scroll="{ x: '1500px', y: getHeight }"
   >
    <span slot="action" slot-scope="text, record">
     <a class="ant-dropdown-link" @click="handleClick(record)">
      보기
      <a-icon type="file-text" />
     </a>
    </span>
   </a-table>
  </div>

  <a-modal
   :closable="false"
   v-model="dialog"
   width="100vw"
   :dialog-style="{ top: '0px' }"
   style="overflow-y: hidden"
   content-class="position-absolute top-0"
  >
   <template slot="footer">
    <a-button key="back" @click="onCloseModal()"> 닫기 </a-button>
   </template>

   <a-row
    class="card-header-padding card-border-bottom"
    style="margin-bottom: 10px; padding-bottom: 0px"
   >
    <a-col :span="24">
     <a-row>
      <a-col :span="24">
       <div class="d-flex d-start">
        <div @click="onClickTab(1)" class="clickable" style="width: 100px">
         <i class="fa-solid fa-book"></i> <span class="ms-1">도서</span>
        </div>
        <div
         @click="onClickTab(4)"
         v-if="selectedItem.urltype === 'application/epub+zip'"
         class="clickable"
         style="width: 100px"
        >
         <i class="fa-solid fa-file-binary"></i> <span class="ms-1">EPUB</span>
        </div>
        <div
         @click="onClickTab(2)"
         class="clickable"
         v-else
         style="width: 100px"
        >
         <i class="fa-solid fa-file-pdf"></i> <span class="ms-1">PDF</span>
        </div>
        <div @click="onClickTab(3)" class="clickable" style="width: 100px">
         <i class="fa-solid fa-block-quote"></i> <span class="ms-1">목차</span>
        </div>
       </div>
      </a-col>
     </a-row>
    </a-col>
   </a-row>

   <div style="height: 100%; padding: 0px; width: 100%; margin: 0px">
    <a-row v-show="step === 1" class="pt-5 pb-5">
     <a-col :span="4" style="margin-right: 0px; padding-right: 0px">
      <div
       style="
        height: 170px;
        border-radius: 2px;
        margin: 0 auto;
        margin-top: 20px;
        margin-right: 20px;
        filter: brightness(0.7);
       "
      >
       <img
        :src="selectedItem.previewImg"
        style="object-fit: contain; height: 100%"
       />
      </div>
     </a-col>
     <a-col :span="20" class="mt-4" style="margin-top: 20px">
      <a-row>
       <a-col :span="6">
        <label class="text-xs text-typo font-weight-bolder ms-1"
         >ISBN/ECN</label
        >
       </a-col>
       <a-col :span="18">: {{ selectedItem.isbn }}</a-col>

       <a-col :span="6">
        <label class="text-xs text-typo font-weight-bolder ms-1"
         >출간(예정일)</label
        >
       </a-col>

       <a-col :span="18">: {{ selectedItem.publishDate }}</a-col>

       <a-col :span="6">
        <label class="text-xs text-typo font-weight-bolder ms-1">저자</label>
       </a-col>
       <a-col :span="18">: {{ selectedItem.author }}</a-col>

       <a-col :span="6">
        <label class="text-xs text-typo font-weight-bolder ms-1">옮긴이</label>
       </a-col>
       <a-col :span="18">: {{ selectedItem.trans }}</a-col>

       <a-col :span="6">
        <label class="text-xs text-typo font-weight-bolder ms-1"
         >총페이지수
        </label>
       </a-col>
       <a-col :span="18">: {{ selectedItem.page }}</a-col>

       <a-col :span="6">
        <label class="text-xs text-typo font-weight-bolder ms-1">정가 </label>
       </a-col>
       <a-col :span="18">: {{ selectedItem.priceraw }}</a-col>

       <a-col :span="6" class="list">
        <label class="text-xs text-typo font-weight-bolder ms-1"
         >카테고리(1차)</label
        >
       </a-col>
       <a-col :span="18">: {{ selectedItem.category1 }}</a-col>
       <a-col :span="6" class="list">
        <label class="text-xs text-typo font-weight-bolder ms-1"
         >카테고리(2차)</label
        >
       </a-col>
       <a-col :span="18">: {{ selectedItem.category2 }}</a-col>
      </a-row>
     </a-col>
    </a-row>
    <a-row v-show="step === 1">
     <a-col :span="24" style="margin-top: 20px">
      <label class="text-xs text-typo font-weight-bolder ms-1">도서명</label>
      <div v-html="selectedItem.title"></div>
     </a-col>
     <a-col :span="24" style="margin-top: 10px">
      <label class="text-xs text-typo font-weight-bolder ms-1">부제</label>
      <div v-html="selectedItem.subtitle"></div>
     </a-col>

     <a-col :span="24" style="margin-top: 10px">
      <label class="text-xs text-typo font-weight-bolder ms-1"
       >줄거리요약</label
      >
      <div v-html="selectedItem.summary"></div>
     </a-col>
     <a-col :span="24" style="margin-top: 10px">
      <label class="text-xs text-typo font-weight-bolder ms-1">출간사평</label>
      <div v-html="selectedItem.review"></div>
     </a-col>
     <a-col :span="24" style="margin-top: 10px">
      <label class="text-xs text-typo font-weight-bolder ms-1">저자소개</label>
      <div v-html="selectedItem.about"></div>
     </a-col>
     <a-col :span="24" style="margin-top: 10px">
      <label class="text-xs text-typo font-weight-bolder ms-1"
       >책 소개 유튜브 영상 URL</label
      >
      <div>{{ selectedItem.youtube }}</div>
     </a-col>
     <a-col :span="24">
      <a-divider></a-divider>
     </a-col>

     <a-col :span="24" v-for="item in papers" :key="item">
      <img style="width: 100%" :src="item" />
     </a-col>
    </a-row>
    <a-row
     v-show="step === 2"
     class="pt-5 pb-0"
     style="height: 100%; overflow-y: hidden"
    >
     <a-col :span="24">
      <div style="margin-top: 0px">
       <a-row class="mt-0">
        <a-col
         :span="24"
         v-if="
          (uploaded === false || contentClone.length === 0) && src === null
         "
        >
         <div class="mb-5">
          <label class="text-xs text-typo font-weight-bolder ms-1"
           >저장된 PDF가 없습니다.</label
          >
         </div>
        </a-col>

        <a-col :span="24" v-if="uploaded">
         <a-row style="padding: 10px; padding-top: 10px">
          <a-col :span="24" style="padding-top: 0px">
           <div
            style="
             display: inline-flex;
             justify-content: space-between;
             width: 100%;
            "
           >
            <div v-if="src" class="p-font-24 p-font-bold">
             {{ currentPage }} / {{ pageCount }}
            </div>
            <div
             v-if="src === null && showStatus && contentClone.length > 0"
             style="margin-left: -12px; width: 100%"
             class="newbook d-flex d-fit"
            >
             <div>총페이지수 : {{ numPages }}</div>
             <div style="margin-top: -10px; margin-left: 10px" class="d-flex">
              <div style="padding: 15px">목차 선택</div>
              <a-select
               v-model="selectedIndex"
               v-on:change="onSelectIndexs($event)"
               style="width: 300px"
              >
               <a-select-option v-for="item in getIndexs" :key="item">
                {{ item }}
               </a-select-option>
              </a-select>
             </div>
            </div>
           </div>
          </a-col>

          <div
           v-if="showStatus === false && contentClone.length !== 0"
           style="margin-top: -30px"
          >
           <a-button
            :ripple="true"
            :elevation="0"
            class="font-weight-bold text-xs btn-default px-6 me-2 ms-auto"
            @click="onLoadMoreBook()"
            color="black"
           >
            저장된 PDF 불러오기
           </a-button>
          </div>
          <a-row
           style="
            width: 100%;
            margin-bottom: 10px;
            height: calc(100vh - 200px);
            overflow-y: scroll;
           "
           v-if="showStatus"
          >
           <a-col
            :span="4"
            v-for="(item, index) in contentClone"
            :key="item.key"
           >
            <div style="margin: 2px">
             <div class="d-flex d-fit" style="position: relative; z-index: 9">
              <div
               class="p-color-dark p-font-16 p-number"
               style="margin-right: -5px"
              >
               {{ index + 1 }}
              </div>
             </div>
             <v-lazy-image
              v-bind:class="[item.active ? 'active' : '']"
              class="p-page"
              :src="item.data"
              style="width: 100%; margin-top: -15px"
             />
            </div>
           </a-col>
          </a-row>
         </a-row>
        </a-col>
       </a-row>
      </div>
     </a-col>
    </a-row>
    <a-row v-show="step === 3" class="pt-0 pb-0">
     <a-col
      :span="6"
      class="p-tree"
      style="height: calc(100vh - 240px); overflow-y: scroll"
     >
      <div>
       <vue-tree-list
        v-if="data != null"
        @select-node="onClick"
        :model="data"
        defaultTreeNodeName="신규챕터"
        v-bind:default-expanded="true"
       >
        <template v-slot:leafNameDisplay="slotProps">
         <span
          class="clickable"
          v-bind:class="[
           slotProps.model.id === selectedChapterItem.id ? 'active' : '',
          ]"
         >
          {{ slotProps.model.name }}
         </span>
        </template>

        <span
         class="icon"
         slot="addTreeNodeIcon"
         style="font-size: 14px"
        ></span>
        <span class="icon" slot="editNodeIcon" style="font-size: 14px"></span>
        <span
         class="icon"
         slot="delNodeIcon"
         style="color: red; font-size: 14px"
        ></span>
        <span
         class="icon"
         slot="addLeafNodeIcon"
         style="font-size: 14px"
        ></span>
        <span class="icon" slot="leafNodeIcon" style="font-size: 14px"></span>

        <span class="icon" slot="treeNodeIcon" style="font-size: 20px"></span>
       </vue-tree-list>
      </div>
     </a-col>
     <a-col
      :span="18"
      style="height: calc(100vh - 240px); overflow-y: scroll; display: block"
     >
      <a-row>
       <a-col :span="16" style="float: right; right: 0; text-align: left">
        <div class="p-font-20 pt-2">{{ selectedChapterItem.name }}</div>
       </a-col>
       <a-col :span="24">
        <div class="d-flex pb-2 mb-2" style="border-bottom: 1px dashed gray">
         <div style="width: 80px; text-align: center">
          <span class="text-typo font-weight-bolder ms-1">순서</span>
         </div>
         <div style="width: 100%; text-align: center">
          <span class="text-typo font-weight-bolder ms-1"
           >목차명(또는 가제)
          </span>
         </div>

         <div style="width: 130px; text-align: center">
          <span class="text-typo font-weight-bolder ms-1">시작페이지</span>
         </div>
         <div style="width: 130px; text-align: center">
          <span class="text-typo font-weight-bolder ms-1">종료페이지</span>
         </div>
         <div style="width: 130px; text-align: center">
          <span class="text-typo font-weight-bolder ms-1">목차가격</span>
         </div>
        </div>

        <div
         class="card-shadow d-flex"
         v-for="(element, idx) in tmpList"
         style="
          justify-content: space-between;
          margin-bottom: 1px;
          margin-top: 1px;
          padding: 1px;
         "
         :key="element.id"
        >
         <div
          class="p-font-24 pt-1 pl-1"
          style="width: 80px; text-align: center; padding-top: 10px"
         >
          {{ idx + 1 }}.
         </div>

         <div style="width: 100%" class="ml-2">
          <a-input
           hide-details
           dense
           flat
           filled
           @keyup="onAutoSave()"
           v-model="element.text"
           solo
           height="43"
           class="input-style font-size-input text-light-input placeholder-light input-icon"
           placeholder="목차명"
          >
          </a-input>
         </div>

         <div style="width: 130px" class="ml-2">
          <a-input
           hide-details
           placeholder="0"
           dense
           type="number"
           v-model="element.start"
           @input="element.start = parseInt($event)"
           min="1"
           @keyup="reCalculateByStartPage(element, $event)"
           max="3000"
           flat
           filled
           solo
           height="43"
           class="input-style font-size-input text-light-input placeholder-light input-icon"
          >
          </a-input>
         </div>
         <div style="width: 130px" class="ml-2">
          <a-input
           hide-details
           placeholder="0"
           dense
           v-model="element.end"
           type="number"
           @input="element.end = parseInt($event)"
           min="1"
           max="3000"
           @keyup="reCalculateByEndPage(element, $event)"
           flat
           filled
           solo
           height="43"
           class="input-style font-size-input text-light-input placeholder-light input-icon"
          >
          </a-input>
         </div>
         <div style="width: 130px" class="ml-2">
          <a-input
           hide-details
           v-model="element.price"
           v-if="dialog"
           placeholder="9900"
           @keyup="keyPressNumber(element, $event)"
           dense
           flat
           filled
           solo
           height="43"
           class="input-style font-size-input text-light-input placeholder-light input-icon"
          >
          </a-input>
         </div>
        </div>
       </a-col>
      </a-row>
     </a-col>
    </a-row>
    <a-row v-show="step === 4" class="pt-0 pb-0">
     <a-col :span="24">
      <div style="margin-top: 0px">
       <a-button
        :ripple="true"
        :elevation="0"
        class="font-weight-bold text-xs btn-default px-6 me-2 ms-auto"
        @click="onLoadEpub()"
        color="black"
        v-if="!ebook"
       >
        저장된 EPUB 불러오기
       </a-button>
       <div class="d-flex d-start">
        <a-button
         v-if="ebook"
         :ripple="true"
         :elevation="0"
         color="black"
         @click="onPreviousPage()"
         class="font-weight-bold text-xs btn-default px-6 me-2 ms-auto"
         >이전 페이지
        </a-button>
        <a-button
         v-if="ebook"
         :ripple="true"
         :elevation="0"
         color="black"
         @click="onNextPage()"
         class="font-weight-bold text-xs btn-default px-6 me-2 ms-auto"
         >다음 페이지
        </a-button>
       </div>
      </div>
      <div
       id="ePubViewer"
       style="width: 95vw; height: 70vh; margin-top: 10px"
      ></div>
     </a-col>
    </a-row>
   </div>
  </a-modal>
 </div>
</template>

<script>
import { getAuth } from "firebase/auth";
import {
 getFirestore,
 addDoc,
 doc,
 collection,
 runTransaction,
 setDoc,
 orderBy,
 updateDoc,
 getDocs,
 query,
 limit,
 where,
} from "firebase/firestore";
import {
 getDatabase,
 ref as dref,
 query as dquery,
 onValue,
 get,
 limitToLast,
} from "firebase/database";

import {
 getCurrentTime2,
 getCurrentTime,
 OPTION_TYPE,
 getCurrentDate3From,
 getCurrentDate3To,
 replaceAll,
 showSuccess,
 showError,
} from "../components/fnc";

import locale from "ant-design-vue/es/date-picker/locale/ko_KR";
import moment from "moment";
import "moment/locale/ko";

import VueTreeList from "../CustomComponent/Tree/VueTreeList";
import { Tree, TreeNode } from "../CustomComponent/Tree/Tree";
import VLazyImage from "v-lazy-image";

const auth = getAuth();
const db = getFirestore();
const db2 = getDatabase();

async function asyncForEach(array, callback) {
 for (let index = 0; index < array.length; index++) {
  await callback(array[index], index, array);
 }
}
function numberWithCommas(x) {
 const parts = x.toString().split(".");
 parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 return parts.join(".");
}

Date.prototype.addDays = function (days) {
 const date = new Date(this.valueOf());
 date.setDate(date.getDate() + days);
 return date;
};

export default {
 components: {
  VLazyImage,
  VueTreeList,
 },
 data() {
  return {
   ebook: null,
   rendition: null,
   epub: "",
   newTree: {},
   papers: [],
   showStatus: false,
   data: new Tree([]),
   chapterMap: new Map(),
   selectedChapterItem: {
    id: "",
    name: "",
    list: [],
   },
   vtab: "",
   title_node: "그룹추가",
   currentPage: 0,
   loadedPage: 0,
   pageCount: 0,
   content: [],
   contentClone: [],
   uploaded: false,
   page: 1,
   step: 1,
   dialog: false,
   tmpList: [],
   categoryList: new Map(),
   selectedItem: {
    title: "",
    subtitle: "",
    category: "",
    previewImg: "",
    previewURL: "",
    partnerId: "",
    category1: "",
    category2: "",
    category3: "",
    category4: "",
    createdAt: "",
    page: 0,
    id: "",
    idx: "",
    isbn: "",
    emailAuthor: "",
    emailPublisher: "",
    author: "",
    mode: 1,
    list: [],
    price: 0,
    index: "",
    auto: false,
    pricePerPage: 0,
    pricePerIndex: 0,
    priceraw: "",
    publishDate: "",
    status: "",
    summary: "",
    review: "",
    about: "",
    tags: [],
    paper: [],
    youtube: "",
   },

   date: [],
   loading: null,
   searchWord: "",
   locale,
   visible: false,
   udata: [],
   cdata: [],
   odata: [],
   tempData: [],
   selectedRowKeys: [],
   expandedRowKeys: [],
   src: null,
   indexs: ["전체"],
   selectedIndex: "전체",
   ucolumns: [
    {
     title: "순서",
     dataIndex: "no",
     key: "no",
     width: 50,
     align: "center",
     fixed: "left",
    },
    {
     title: "요청일",
     dataIndex: "eventDttm",
     key: "eventDttm",
     sorter: (a, b) => a.createdAt - b.createdAt,
     width: 170,
    },
    {
     title: "도서명",
     dataIndex: "title",
     key: "title",
     width: 200,
    },
    {
     title: "작가",
     dataIndex: "author",
     key: "author",
     width: 110,
    },
    {
     title: "브랜드",
     dataIndex: "brand",
     key: "brand",
     width: 110,
    },
    {
     title: "카테고리",
     dataIndex: "category",
     key: "category",
     width: 180,
    },
    {
     title: "이메일",
     dataIndex: "email",
     key: "email",
     width: 180,
    },
    {
     title: "총페이지",
     dataIndex: "page",
     key: "page",
     width: 70,
    },
    {
     title: "가격",
     dataIndex: "price",
     key: "price",
     width: 100,
    },
    {
     title: "상태",
     dataIndex: "status",
     key: "status",
     width: 70,
    },
    {
     title: "검수",
     key: "action",
     width: 100,
     scopedSlots: { customRender: "action" },
    },
   ],
  };
 },
 methods: {
  async onPreviousPage() {
   await this.rendition.prev();
   // await this.rendition.currentLocation();
  },
  async onNextPage() {
   await this.rendition.next();
  },
  async fncLoadEpub() {
   this.loading = this.$vs.loading();
   let _all = "";
   const q = query(
    collection(db, "epub"),
    where("bookid", "==", this.selectedItem.id),
    orderBy("idx", "asc")
   );

   const querySnapshot = await getDocs(q);
   querySnapshot.forEach((doc) => {
    _all += doc.data().data;
   });
   this.epub = _all;

   this.loading.close();
  },
  async onLoadEpub() {
   this.loading = this.$vs.loading();
   this.ebook = await ePub(this.epub, { encoding: "base64" });
   const b64 = this.epub.replace("data:application/epub+zip;base64,", "");
   this.ebook.open(b64);
   this.rendition = await this.ebook.renderTo("ePubViewer", {
    method: "default",
    flow: "paginated",
    allowScriptedContent: true,
    width: "100%",
    height: "100%",
   });

   // * Total Page 수 대략적인..
   this.ebook.ready
    .then(async (book) => {
     return await this.ebook.locations.generate(300);
    })
    .then((locations) => {
     this.selectedItem.page = locations.length;
    });

   await this.rendition.display();
   this.loading.close();
  },
  fncReset() {
   this.step = 1;
   this.selectedItem = {
    title: "",
    subtitle: "",
    category: "",
    previewImg: "",
    previewURL: "",
    partnerId: "",
    category1: "",
    category2: "",
    category3: "",
    category4: "",
    createdAt: "",
    page: 0,
    id: "",
    idx: "",
    isbn: "",
    emailAuthor: "",
    emailPublisher: "",
    author: "",
    mode: 1,
    list: [],
    price: 0,
    index: "",
    auto: false,
    pricePerPage: 0,
    pricePerIndex: 0,
    priceraw: "",
    publishDate: "",
    status: "",
    summary: "",
    review: "",
    about: "",
    tags: [],
    paper: [],
    youtube: "",
   };
   this.data = new Tree([]);
   this.content = [];
   this.papers = [];
   this.contentClone = [];
   this.showStatus = false;
   this.tmpList = [];
   this.pageList = [];
   this.selectedIndex = "전체";
   this.chapterMap = new Map();
   this.uploaded = false;
   this.src = null;
   this.selectedChapterItem = {
    id: "",
    name: "",
    list: [],
   };
  },
  fncGetIndex(_title) {
   let _r = null;
   const _json = Object.fromEntries(this.chapterMap);
   for (const k in _json) {
    const _item_ = _json[k];

    _item_.forEach((ii) => {
     if (ii.text === _title) {
      _r = ii;
     }
    });
   }

   return _r;
  },
  onSelectIndexs(e) {
   this.selectedIndex = e;

   let _a = {
    start: 0,
    end: 0,
   };
   if (e !== "전체") {
    _a = this.fncGetIndex(e);
   }

   this.contentClone = this.contentClone.filter((item) => {
    if (item.page >= _a.start && item.page <= _a.end) {
     item["active"] = true;
    } else {
     item["active"] = false;
    }
    return item;
   });
  },
  onCloseModal() {
   this.dialog = false;
   this.fncReset();
  },
  handleClick(item) {
   const self = this;
   this.selectedItem = Object.assign({}, item);
   this.isAdvanced =
    this.selectedItem.advanced === undefined
     ? false
     : this.selectedItem.advanced;

   if (this.selectedItem.list) {
    this.chapterMap = new Map(Object.entries(this.selectedItem.list));
   }

   this.dialog = true;
   if (this.selectedItem.index) {
    this.data = new Tree(JSON.parse(this.selectedItem.index));
   } else {
    this.data = new Tree([]);
   }

   if (this.selectedItem.urltype === "application/epub+zip") {
    this.fncLoadEpub();
   } else {
    this.fncLoadPageList(this.selectedItem.id, true);
   }
   this.fncLoadPaperList(this.selectedItem.paper);
  },
  async fncLoadPaperList(_paper) {
   if (_paper.length > 0) {
    const self = this;
    const q = query(collection(db, "paper"), where("id", "in", _paper));

    const querySnapshot = await getDocs(q);
    this.numPages = querySnapshot.docs.length;
    querySnapshot.forEach((doc) => {
     self.selectedItem.paper.forEach((item) => {
      if (item === doc.data().id) {
       if (doc.data().url !== undefined) {
        self.papers.push(doc.data().url);
       }
      }
     });
    });
   }
  },
  onLoadMoreBook() {
   this.fncLoadPageList(this.selectedItem.id, false);
   this.showStatus = true;
  },
  async fncLoadPageList(_id, _flag) {
   const self = this;
   const _limit = _flag ? 1 : 1000;
   this.contentClone = [];
   this.loading = this.$vs.loading();
   const q = query(
    collection(db, "master_page"),
    where("bookid", "==", _id),
    limit(_limit),
    orderBy("page", "asc")
   );
   const querySnapshot = await getDocs(q);
   this.numPages = querySnapshot.docs.length;
   querySnapshot.forEach((doc) => {
    const _d = doc.data();
    _d["id"] = doc.id;
    _d["checked"] = false;
    _d["active"] = false;
    self.contentClone.push(_d);
    self.uploaded = true;
   });
   this.loading.close();
  },
  onClick(params) {
   if (params.children === null || params.children.length === 0) {
    this.selectedChapterItem = params;

    this.tmpList = [];
    const _list = this.chapterMap.get(this.selectedChapterItem.id.toString());
    if (_list) {
     this.tmpList = _list;
    }
   }
  },
  onClickTab(n) {
   this.step = n;
  },
  async onVerify() {
   const self = this;
   if (this.selectedRowKeys.length === 0) {
    showError({
     notification: this.$vs.notification,
     msg: "도서를 선택해주세요",
    });
   } else {
    await asyncForEach(
     this.expandedRowKeys,
     async function (item, index, array) {
      const updateRef = doc(db, "master", item.id);

      await updateDoc(updateRef, {
       status: "검수완료",
      });
     }
    );
    showSuccess({
     notification: this.$vs.notification,
     msg: "성공적으로 업데이트 하였습니다.",
    });
    this.onLoadRequest();
    this.selectedRowKeys = [];
   }
  },
  async onReject() {
   const self = this;
   if (this.selectedRowKeys.length === 0) {
    showError({
     notification: this.$vs.notification,
     msg: "도서를 선택해주세요",
    });
   } else {
    await asyncForEach(
     this.expandedRowKeys,
     async function (item, index, array) {
      const updateRef = doc(db, "master", item.id);

      await updateDoc(updateRef, {
       status: "반려",
      });
     }
    );
    showSuccess({
     notification: this.$vs.notification,
     msg: "성공적으로 업데이트 하였습니다.",
    });
    this.onLoadRequest();
    this.selectedRowKeys = [];
   }
  },

  onSelectChange(selectedRowKeys) {
   const self = this;
   this.expandedRowKeys = [];

   this.selectedRowKeys = selectedRowKeys;

   selectedRowKeys.forEach((ii) => {
    self.odata.forEach((item) => {
     if (parseInt(item.no) === parseInt(ii)) {
      self.expandedRowKeys.push(item);
     }
    });
   });
  },
  async onLoadRequest() {
   const self = this;

   this.odata = [];
   this.udata = [];

   this.loading = this.$vs.loading();
   const q = query(
    collection(db, "master"),
    where("status", "==", "검수중"),
    orderBy("createdAt", "desc")
   );

   const _data = [];
   let _idx = 1;
   let _current = [];

   const querySnapshot = await getDocs(q);
   querySnapshot.forEach((doc) => {
    const _d = doc.data();
    _d["no"] = _idx;
    _d["id"] = doc.id;
    _d["bidratio"] = doc.data().bidratio ? doc.data().bidratio : 10;
    // const _t = doc.data().standardPrice ?  doc.data().standardPrice : 500;

    _d["pricePerPage"] = parseInt(
     (doc.data().priceraw * ((parseInt(_d["bidratio"]) + 100) / 100)) /
      _d["page"]
    );

    _d["idx"] = doc.data().idx === undefined ? 0 : doc.data().idx;

    _d["pricePerIndex"] = parseInt(
     (doc.data().priceraw * ((parseInt(_d["bidratio"]) + 100) / 100)) /
      _d["idx"]
    );

    _d["mode"] = doc.data().mode === undefined ? 1 : doc.data().mode;
    // _d["mode2"] = doc.data().mode2 === undefined ? false : doc.data().mode2;

    _d["min"] = doc.data().min === undefined ? 0 : doc.data().min;
    _d["max"] = doc.data().max === undefined ? 0 : doc.data().max;

    _d["auto"] = doc.data().auto === undefined ? false : doc.data().auto;
    _d["emailAuthor"] =
     doc.data().emailAuthor === undefined ? "" : doc.data().emailAuthor;
    _d["emailPublisher"] =
     doc.data().emailPublisher === undefined ? "" : doc.data().emailPublisher;
    _d["paper"] = doc.data().paper === undefined ? [] : doc.data().paper;
    _d["trans"] = doc.data().trans ? doc.data().trans : "";

    _d["eventDttm"] = getCurrentTime(new Date(doc.data().createdAt));
    _data.push(_d);
    _idx++;
   });

   this.odata = _data;
   this.udata = _data;
   this.loading.close();
  },
  onSearch() {
   const self = this;
   this.udata = [];
   this.udata = this.odata.filter((item) => {
    if (item.title.toLowerCase().indexOf(self.searchWord.toLowerCase()) >= 0) {
     return item;
    }
   });
   this.selectedRowKeys = [];
  },
 },
 computed: {
  getIndexs() {
   const _r = [];
   const _json = Object.fromEntries(this.chapterMap);
   for (const k in _json) {
    const _item_ = _json[k];

    _item_.forEach((ii) => {
     _r.push(ii);
    });
   }

   const _r_ = _r.sort(function (a, b) {
    // asc
    return a.start < b.start ? -1 : a.start > b.start ? 1 : 0;
   });

   const __r__ = ["전체"];

   _r_.forEach((item) => {
    __r__.push(item.text);
   });

   return __r__;
  },

  getHeight() {
   return "calc(100vh - 210px)";
  },
  getHeight2() {
   return "calc(100vh - 300px)";
  },
 },
 mounted() {
  this.onLoadRequest();
 },
};
</script>

<style>
.p-page {
 border: 1px solid #e2e2e2;
}
.p-page.active {
 border: 2px dashed #3d51f4;
}
.v-lazy-image {
 filter: blur(10px);
 transition: filter 0.7s;
}

.v-lazy-image-loaded {
 filter: blur(0);
}

.p-tree .active {
 background-color: #3c52f4;
 color: white;
 font-weight: bold;
 padding: 3px;
 border-radius: 3px;
}
</style>