<template>
 <!-- Information Card 2 -->
 <a-card :bordered="false" class="card-info-2 h-full">
  <div
   class="col-content h-full"
   style="background-image: url('images/info-card-2.jpg')"
  >
   <div class="card-content">
    <h5>회원수(UA)</h5>
    <h1 style="color: white" v-if="count">{{(count).toLocaleString()}}</h1>
   </div>
  </div>
 </a-card>
 <!-- / Information Card 2 -->
</template>

<script>
export default {
 props: {
  count: {
   type: Number,
   default: 0,
  },
 },
 data() {
  return {};
 },
};
</script>