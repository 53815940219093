<template>
 <div>
  <div
   style="display: inline-flex; width: 100%; justify-content: space-between"
  >
   <div class="d-flex d-fit" style="width: 100%">
    <div>
     <!-- <a-input-search
      placeholder="사업자번호 혹은 작가 이메일 검색"
      style="width: 300px"
      v-model="searchWord"
      @search="onSearch"
     /> -->
    </div>
    <div>
     <a-button style="margin-right: 10px" @click="onModify()"
      >수정 하기</a-button
     >
     <a-button @click="onCreate()">등록 하기</a-button>
    </div>
   </div>

   <div
    style="display: inline-flex; width: auto; justify-content: space-between"
   >
    <!-- <div style="margin-right: 10px">
     <a-button @click="onShowHistory()">선택 사용자 이력조회</a-button>
    </div>
    <div style="padding: 10px">총 선택 인원 {{ selectedRowKeys.length }}</div> -->
   </div>
  </div>
  <div style="margin-top: 10px">
   <a-table
    :row-selection="{
     selectedRowKeys: selectedRowKeys,
     onChange: onSelectChange,
    }"
    :columns="scolumns"
    :data-source="sdata"
    :pagination="false"
    bordered
    :rowKey="(record) => record.idx"
    size="middle"
    :scroll="{ x: '1000px', y: getHeight }"
   >
   </a-table>
  </div>
  <a-modal v-model="visible" title="출판사별 공급가율 입력">
   <template slot="footer">
    <a-button key="back" @click="visible = false"> 취소 </a-button>
    <a-button key="submit" type="primary" @click="onSaveMessage()">
     저장
    </a-button>
   </template>
   <div>
    <a-input placeholder="출판사 상호명 입력" v-model="supply.title" />
   </div>
   <div class="d-flex d-fit" style="margin-top: 10px">
    <a-input
     placeholder="출판사 사업자등록번호(숫자만) 또는 작가 이메일 입력"
     v-model="supply.id"
    />
    <a-input
     placeholder="공급가율"
     style="margin-left: 10px; width: 100px"
     v-model="supply.ratio"
    />
   </div>
  </a-modal>
 </div>
</template>
   
<script>
import {
 getFirestore,
 addDoc,
 doc,
 collection,
 runTransaction,
 setDoc,
 orderBy,
 updateDoc,
 getDocs,
 query,
 where,
} from "firebase/firestore";

import {
 getCurrentTime2,
 OPTION_TYPE,
 getCurrentDate3From,
 getCurrentDate3To,
 replaceAll,
 showSuccess,
 showError,
} from "../components/fnc";

const db = getFirestore();

export default {
 data() {
  return {
   date: [],
   searchWord: "",
   message: "",
   supply: {
    id: "",
    title: "",
    ratio: null,
   },
   selectedRowKeys: [],
   expandedRowKeys: [],
   scolumns: [
    {
     title: "순서",
     dataIndex: "idx",
     key: "idx",
     width: 100,
     align: "center",
    },
    {
     title: "상호명",
     dataIndex: "title",
     key: "title",
    },
    {
     title: "사업자번호 / 작가 이메일",
     dataIndex: "id",
     key: "id",
    },
    {
     title: "공급가율",
     dataIndex: "ratio",
     key: "ratio",
     width: 200,
    },
    {
     title: "등록일",
     dataIndex: "eventdttm",
     key: "eventdttm",
     width: 200,
    },
   ],
   sdata: [],
   visible: false,
  };
 },
 methods: {
  onSearch() {},
  onCreate() {
   this.supply = {
    id: "",
    title: "",
    ratio: null,
   };
   this.visible = true;
  },
  onModify() {
   const _s = this.expandedRowKeys[0];

   if (this.expandedRowKeys.length === 0) {
    showError({
     notification: this.$vs.notification,
     msg: "수정을 위해 데이타를 선택해주세요.",
    });
   } else {
    const _idx = this.expandedRowKeys[0];

    const _u = this.data.find((item) => item.id === _idx.id);

    this.supply = {
     id: _u.id,
     title: _u.title,
     ratio: _u.ratio,
    };
    this.visible = true;
   }
  },
  async onLoadSupply() {
   const self = this;

   this.data = [];
   this.sdata = [];

   this.loading = this.$vs.loading();

   const q = query(collection(db, "supply_rate"), orderBy("createdAt", "desc"));

   const _data = [];
   let _idx = 1;
   const querySnapshot = await getDocs(q);
   let _current = [];
   querySnapshot.forEach((doc) => {
    const _t = doc.data();
    console.log(_t);
    _t["idx"] = _idx;

    _t["eventdttm"] = getCurrentTime2(_t.createdAt);

    _idx++;

    _data.push(_t);
   });
   this.data = _data;
   this.sdata = _data;
   this.loading.close();
  },
  async onSaveMessage() {
   const self = this;
   if (this.supply.id && this.supply.ratio && this.supply.title) {
    this.loading = this.$vs.loading();
    this.supply["createdAt"] = new Date().getTime();

    if (this.supply.id.indexOf("@") < 0) {
     this.supply["id"] = replaceAll(this.supply["id"], "-", "");
    }
    this.supply["createdAt"] = new Date().getTime();

    await setDoc(doc(db, "supply_rate", this.supply["id"]), this.supply, {
     merge: true,
    });

    this.loading.close();
    this.visible = false;
    showSuccess({
     notification: this.$vs.notification,
     msg: "성공적으로 저장 하였습니다.",
    });
    this.onLoadSupply();
    this.selectedRowKeys = [];
   } else {
    showError({
     notification: this.$vs.notification,
     msg: "모든 값은 필수 입력 입니다.",
    });
   }
  },
  onSelectChange(selectedRowKeys) {
   const self = this;
   this.expandedRowKeys = [];

   this.selectedRowKeys = selectedRowKeys;

   selectedRowKeys.forEach((ii) => {
    self.sdata.forEach((item) => {
     if (parseInt(item.idx) === parseInt(ii)) {
      self.expandedRowKeys.push(item);
     }
    });
   });
  },
 },
 computed: {
  getHeight() {
   return "calc(100vh - 210px)";
  },
  getHeight2() {
   return "calc(100vh - 300px)";
  },
 },
 mounted() {
  this.onLoadSupply();
 },
};
</script>
   
<style></style>