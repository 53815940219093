<template>
 <div>
  <div
   style="display: inline-flex; width: 100%; justify-content: space-between"
  >
   <div style="width: 100%" class="d-flex d-fit">
    <div><b>추천도서</b></div>
    <div>
     <a-button @click="onDelete()" type="danger">추천 도서에서 내리기</a-button>
    </div>
   </div>
  </div>
  <div style="margin-top: 10px">
   <a-table
    :row-selection="{
     selectedRowKeys: selectedRowKeysHot,
     onChange: onSelectChangeHot,
    }"
    :columns="ucolumns"
    :data-source="udata"
    :pagination="false"
    bordered
    :rowKey="(record) => record.no"
    size="middle"
    :scroll="{ x: '1500px', y: '50vw' }"
   >
   </a-table>
  </div>

  <a-divider></a-divider>
  <div
   style="display: inline-flex; width: 100%; justify-content: space-between"
  >
   <div style="width: 100%" class="d-flex d-fit">
    <div>
     <div>
      <a-input-search
       placeholder="저자명 검색"
       style="width: 200px"
       v-model="searchWord"
       @search="onSearch"
      />
     </div>
    </div>
    <div>
     <a-button @click="onAssign()" type="primary">추천 도서로 올리기</a-button>
    </div>
   </div>
  </div>
  <div style="margin-top: 10px">
   <a-table
    :row-selection="{
     selectedRowKeys: selectedRowKeys,
     onChange: onSelectChange,
    }"
    :columns="ucolumns"
    :data-source="odata"
    :pagination="false"
    bordered
    :rowKey="(record) => record.no"
    size="middle"
    :scroll="{ x: '1500px', y: '50vw' }"
   >
   </a-table>
  </div>
 </div>
</template>

<script>
import { getAuth } from "firebase/auth";
import {
 getFirestore,
 addDoc,
 deleteDoc,
 doc,
 collection,
 runTransaction,
 setDoc,
 orderBy,
 updateDoc,
 getDocs,
 query,
 limit,
 where,
} from "firebase/firestore";
import {
 getDatabase,
 ref as dref,
 query as dquery,
 onValue,
 get,
 limitToLast,
} from "firebase/database";

import {
 getCurrentTime,
 showSuccess,
 showError,
} from "../components/fnc";

import locale from "ant-design-vue/es/date-picker/locale/ko_KR";
import moment from "moment";
import "moment/locale/ko";


const auth = getAuth();
const db = getFirestore();
const db2 = getDatabase();

async function asyncForEach(array, callback) {
 for (let index = 0; index < array.length; index++) {
  await callback(array[index], index, array);
 }
}
function numberWithCommas(x) {
 const parts = x.toString().split(".");
 parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 return parts.join(".");
}

Date.prototype.addDays = function (days) {
 const date = new Date(this.valueOf());
 date.setDate(date.getDate() + days);
 return date;
};

export default {
 components: {

 },
 data() {
  return {
   papers: [],
   showStatus: false,
   title_node: "그룹추가",
   currentPage: 0,
   loadedPage: 0,
   pageCount: 0,
   content: [],
   contentClone: [],
   uploaded: false,
   page: 1,
   step: 1,
   dialog: false,
   tmpList: [],
   date: [],
   loading: null,
   searchWord: "",
   locale,
   visible: false,
   udata: [],
   odata: [],
   selectedRowKeys: [],
   expandedRowKeys: [],
   selectedRowKeysHot: [],
   expandedRowKeysHot: [],
   indexs: ["전체"],
   selectedIndex: "전체",
   ucolumns: [
    {
     title: "순서",
     dataIndex: "no",
     key: "no",
     width: 50,
     align: "center",
     fixed: "left",
    },
    {
     title: "요청일",
     dataIndex: "eventDttm",
     key: "eventDttm",
     sorter: (a, b) => a.createdAt - b.createdAt,
     width: 170,
    },
    {
     title: "도서명",
     dataIndex: "title",
     key: "title",
     width: 200,
    },
    {
     title: "작가",
     dataIndex: "author",
     key: "author",
     width: 110,
    },
    {
     title: "브랜드",
     dataIndex: "brand",
     key: "brand",
     width: 110,
    },
    {
     title: "카테고리",
     dataIndex: "category",
     key: "category",
     width: 180,
    },
    {
     title: "이메일",
     dataIndex: "email",
     key: "email",
     width: 180,
    },
    {
     title: "총페이지",
     dataIndex: "page",
     key: "page",
     width: 70,
    },
    {
     title: "가격",
     dataIndex: "price",
     key: "price",
     width: 100,
    },
    {
     title: "상태",
     dataIndex: "status",
     key: "status",
     width: 70,
    },
   ],
  };
 },
 methods: {
  async onAssign() {
   const self = this;
   if (this.selectedRowKeys.length === 0) {
    showError({
     notification: this.$vs.notification,
     msg: "도서를 선택해주세요",
    });
   } else {
    await asyncForEach(
     this.expandedRowKeys,
     async function (item, index, array) {
      await addDoc(collection(db, "hot_book"), item);
     }
    );
    showSuccess({
     notification: this.$vs.notification,
     msg: "성공적으로 등록 되었습니다.",
    });
    this.selectedRowKeys = [];
    this.onLoadHotBook();
   }
  },
  async onDelete() {
   const self = this;
   if (this.selectedRowKeysHot.length === 0) {
    showError({
     notification: this.$vs.notification,
     msg: "추천 도서를 선택해주세요",
    });
   } else {
    await asyncForEach(
     this.expandedRowKeysHot,
     async function (item, index, array) {
      await deleteDoc(doc(db, "hot_book", item.id));
     }
    );
    showSuccess({
     notification: this.$vs.notification,
     msg: "성공적으로 삭제 되었습니다.",
    });
    this.onLoadHotBook();
    this.selectedRowKeysHot = [];
   }
  },

  onSelectChange(selectedRowKeys) {
   const self = this;
   this.expandedRowKeys = [];

   this.selectedRowKeys = selectedRowKeys;

   selectedRowKeys.forEach((ii) => {
    self.odata.forEach((item) => {
     console.log(ii, item.no);
     if (parseInt(item.no) === parseInt(ii)) {
      self.expandedRowKeys.push(item);
     }
    });
   });
  },

  onSelectChangeHot(selectedRowKeys) {
   const self = this;
   this.expandedRowKeysHot = [];

   this.selectedRowKeysHot = selectedRowKeys;

   selectedRowKeys.forEach((ii) => {
    self.udata.forEach((item) => {
     if (parseInt(item.no) === parseInt(ii)) {
      self.expandedRowKeysHot.push(item);
     }
    });
   });
  },
  async onLoadHotBook() {
   const self = this;

   this.udata = [];

   this.loading = this.$vs.loading();
   const q = query(collection(db, "hot_book"), orderBy("createdAt", "desc"));

   const _data = [];
   let _idx = 1;
   let _current = [];

   const querySnapshot = await getDocs(q);
   querySnapshot.forEach((doc) => {
    const _d = doc.data();
    _d["no"] = _idx;
    _d["id"] = doc.id;

    _d["eventDttm"] = getCurrentTime(new Date(doc.data().createdAt));
    _data.push(_d);
    _idx++;
   });

   this.udata = _data;
   this.loading.close();
  },
  async onSearch() {
   const self = this;
   this.odata = [];

   this.loading = this.$vs.loading();
   const q = query(
    collection(db, "master"),
    where("status", "==", "배포"),
    where("author", "==", this.searchWord),
    orderBy("createdAt", "desc")
   );

   let _idx = 1;

   const querySnapshot = await getDocs(q);
   querySnapshot.forEach((doc) => {
    const _d = doc.data();
    _d["no"] = _idx;
    _d["id"] = doc.id;

    _d["eventDttm"] = getCurrentTime(new Date(doc.data().createdAt));
    self.odata.push(_d);
    _idx++;
   });
   this.loading.close();

   this.selectedRowKeys = [];
  },
 },
 computed: {
  getIndexs() {
   const _r = [];
   const _json = Object.fromEntries(this.chapterMap);
   for (const k in _json) {
    const _item_ = _json[k];

    _item_.forEach((ii) => {
     _r.push(ii);
    });
   }

   const _r_ = _r.sort(function (a, b) {
    // asc
    return a.start < b.start ? -1 : a.start > b.start ? 1 : 0;
   });

   const __r__ = ["전체"];

   _r_.forEach((item) => {
    __r__.push(item.text);
   });

   return __r__;
  },

  getHeight() {
   return "calc(100vh - 210px)";
  },
  getHeight2() {
   return "calc(100vh - 300px)";
  },
 },
 mounted() {
  this.onLoadHotBook();
 },
};
</script>

<style>
.p-page {
 border: 1px solid #e2e2e2;
}
.p-page.active {
 border: 2px dashed #3d51f4;
}
.v-lazy-image {
 filter: blur(10px);
 transition: filter 0.7s;
}

.v-lazy-image-loaded {
 filter: blur(0);
}

.p-tree .active {
 background-color: #3c52f4;
 color: white;
 font-weight: bold;
 padding: 3px;
 border-radius: 3px;
}
</style>