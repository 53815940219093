<template>
 <div>
  <div
   style="display: inline-flex; width: 100%; justify-content: space-between"
  >
   <div>
    <a-input-search
     placeholder="사용자 이름 검색"
     style="width: 200px"
     v-model="searchWord"
     @search="onSearch"
    />
   </div>

   <div
    style="display: inline-flex; width: auto; justify-content: space-between"
   >
    <!-- <div style="margin-right: 10px">
     <a-button @click="onShowHistory()">선택 사용자 이력조회</a-button>
    </div>
    <div style="padding: 10px">총 선택 인원 {{ selectedRowKeys.length }}</div> -->
   </div>
  </div>
  <div style="margin-top: 10px">
   <a-table
    :columns="ucolumns"
    :data-source="udata"
    :pagination="false"
    bordered
    :rowKey="(record) => record.uid"
    size="middle"
    :scroll="{ y: getHeight, x: '1000px' }"
   >
   </a-table>
  </div>
 </div>
</template>

<script>
import { getAuth } from "firebase/auth";
import {
 getFirestore,
 addDoc,
 doc,
 collection,
 runTransaction,
 setDoc,
 orderBy,
 updateDoc,
 getDocs,
 query,
 where,
} from "firebase/firestore";
import {
 getDatabase,
 ref as dref,
 query as dquery,
 onValue,
 get,
 limitToLast,
} from "firebase/database";

import {
 getCurrentTime2,
 OPTION_TYPE,
 getCurrentDate3From,
 getCurrentDate3To,
 replaceAll,
 showSuccess,
 showError,
} from "../components/fnc";

import locale from "ant-design-vue/es/date-picker/locale/ko_KR";
import moment from "moment";
import "moment/locale/ko";
const auth = getAuth();
const db = getFirestore();
const db2 = getDatabase();

async function asyncForEach(array, callback) {
 for (let index = 0; index < array.length; index++) {
  await callback(array[index], index, array);
 }
}
Date.prototype.addDays = function (days) {
 const date = new Date(this.valueOf());
 date.setDate(date.getDate() + days);
 return date;
};

export default {
 data() {
  return {
   date: [],
   loading: null,
   searchWord: "",
   locale,
   data: [],
   visible: false,
   udata: [],
   cdata: [],
   odata: [],
   tempData: [],
   selectedRowKeys: [],
   ucolumns: [
    {
     title: "순서",
     dataIndex: "idx",
     key: "idx",
     width: 50,
     align: "center",
    },
    {
     title: "가입일",
     dataIndex: "eventdttm",
     key: "eventdttm",
     sorter: (a, b) => a.createdAt - b.createdAt,
     width: 170,
    },
    {
     title: "이름",
     dataIndex: "username",
     key: "username",
     width: 100,
    },
    {
     title: "아이디",
     dataIndex: "email",
     key: "email",
     width: 200,
    },
    {
     title: "사용자타입",
     dataIndex: "type",
     key: "type",
     width: 100,
     filters: [
      { text: "사용자", value: "사용자" },
      { text: "파트너[작가]", value: "파트너[작가]" },
      { text: "파트너[출판사]", value: "파트너[출판사]" },
     ],
     onFilter: (value, record) => record.type.indexOf(value) === 0,
    },
    {
     title: "파트너아이디",
     dataIndex: "partnerId",
     key: "parterId",
     width: 140,
    },
    {
     title: "마케팅동의여부",
     dataIndex: "market",
     key: "market",
     width: 100,
    },
    {
     title: "마지막접속시간",
     dataIndex: "lastevetdttm",
     key: "lastevetdttm",
     sorter: (a, b) => a.lastLoginTime - b.lastLoginTime,
     //  width: 100,
    },
   ],
  };
 },
 methods: {
  async onLoadUser() {
   const self = this;

   this.odata = [];
   this.udata = [];

   this.loading = this.$vs.loading();

   const q = query(collection(db, "User"), orderBy("createdAt", "desc"));

   const _data = [];
   let _idx = 1;
   const querySnapshot = await getDocs(q);
   let _current = [];
   querySnapshot.forEach((doc) => {
    const _t = doc.data();
    _t["idx"] = _idx;
    const _a = _t.accountType
     ? _t.accountType === "A"
       ? "작가"
       : "출판사"
     : "출판사";


    if(_t.accountType === "P" && _t["email"].indexOf("@puzzle.live") >=0 )
    {
      _t["email"] = replaceAll(_t["email"],"@puzzle.live","");
    }

    _t["type"] = _t.userType === "P" ? "파트너[" + _a + "]" : "사용자";
    _t["partnerId"] = _t.partnerId ? _t.partnerId : "";
    const _b = _t.agree ? (_t.agree.type3 ? "동의" : "미동의") : "";
    _t["market"] = _b;
    _t["eventdttm"] = getCurrentTime2(_t.createdAt);
    _t["lastevetdttm"] = _t.lastLoginTime
     ? getCurrentTime2(_t.lastLoginTime)
     : "-";
    _idx++;
    _data.push(_t);
   });
   this.odata = _data;
   this.udata = _data;
   this.loading.close();
  },
  onSearch() {
   const self = this;
   this.udata = [];
   this.udata = this.odata.filter((item) => {
    if (item.username) {
     if (
      item.username.toLowerCase().indexOf(self.searchWord.toLowerCase()) >= 0
     ) {
      return item;
     }
    } else if (self.searchWord === "") {
     return item;
    }
   });
   //  this.selectedRowKeys = [];
  },
 },
 computed: {
  getHeight() {
   return "calc(100vh - 210px)";
  },
  getHeight2() {
   return "calc(100vh - 300px)";
  },
 },
 mounted() {
  this.onLoadUser();
 },
};
</script>

<style>
</style>